<template>
  <Headline v-if="$props.headline" type="h3" classes="subline3 mb-2.5" :text="$props.headline" />
  <div class="button-group mb-10">
    <Contact v-for="button of $props.buttons" :key="button.id" :type="button.type" :label="button.label" :href="button.url" :content="button.content" />
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

const Contact = defineAsyncComponent(() => import("@/modules/Contact"))
const Headline = defineAsyncComponent(() => import("@/components/Headline"))
export default {
  name: "ContactGroup",
  props: ["buttons", "headline"],
  components: {
    Contact,
    Headline
  }
}
</script>